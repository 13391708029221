.new-chat-title {
  font-size: 20px;
  font-family: Inter;
  font-weight: 700 !important;
}

.group-name-input {
  width: 70%;
  height: 40px;
  color: #7e8b99;
}

.group-mem {
  padding-bottom: 10px;
  font-family: Inter;
  font-weight: 700;
}

.create-new-group {
  width: 70%;
  border-radius: 6px;
}
