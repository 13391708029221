table thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.arrow-up:hover,
.arrow-up:active {
  color: #3062d4 !important;
}
.arrow-down:hover,
.arrow-down:active {
  color: #3062d4 !important;
}
.search-input {
  font-size: 16px;
  font-weight: 400;
  padding: 4px;
  margin: 2px 3px;
  /* outline-color: #c9cacb !important; */
  outline: none;
  border-radius: 0.35rem;
  border: 2px solid #c9cacb !important;
  width: 100%;
}
.search-input:focus-visible {
  border: 1px solid grey;
  /* outline-color: #c9cacb !important; */
  outline: none;
}
.bg-highlight {
  background-color: #d1e0fb;
}
.search-icon-input {
  position: absolute;
  color: #c9cacb;
  right: 13px;
  top: 13px;
}
.no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-weight: 600;
  color: #bdbdbd;
}
.standard-reports table td {
  text-align: center;
}
.row > * {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}
.standard-reports .dropdown-icon {
  color: black !important;
}
.standard-reports .MuiStack-root {
  overflow: initial !important;
}
.standard-reports .MuiInputLabel-root {
  color: #bdbdbd !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  top: -7px !important;
}
.standard-reports .MuiInputBase-input {
  padding: 0.5rem !important;
}
.standard-reports .MuiTypography-root {
  margin-left: 10px !important;
}
.standard-report .MuiStack-root {
  padding-top: 0px !important;
}
.standard-reports .MuiOutlinedInput-notchedOutline {
  display: none !important;
}
.standard-reports .MuiInputBase-input {
  outline: solid !important;
  border-radius: 0.375rem !important;
  outline-color: #e5e7eb !important;
}
.standard-reports .MuiInputLabel-shrink {
  display: none !important;
}
.standard-reports .MuiStack-root {
  width: 100% !important;
}
.standard-reports .MuiTabs-flexContainer button {
  margin: 0px 0.5rem 0 0 !important;
  border-radius: 0.5rem !important;
  background-color: #e5eeff !important;
  color: #414142 !important;
  text-transform: capitalize;
  font-weight: 600 !important;
  min-height: 0px !important;
  padding: 8px 5px !important;
  font-size: 14px !important;
  border: 2px solid #e5eeff !important;
}
.standard-reports .MuiTabs-flexContainer .Mui-selected {
  color: #000000 !important;
  border: 2px solid black !important;
}
.standard-reports .MuiTabs-root {
  min-height: 0px !important;
}

.pvtUi .pvtOutput .pvtTable {
  width: 100%;
  margin: 0px !important;
}

.pvtUi tr > td:first-child {
  width: 17%;
  min-width: 30vh;
}
/* .pvtVals .pvtDropdown:nth-of-type(2) {
  display: none;
} */
table.pvtTable thead tr th {
  background-color: #d1e0fb !important;
  /* border: 1px solid #7b97bb !important; */
  color: #212529;
  font-size: 0.95rem !important;
  outline: solid;
  outline-offset: -1px;
  outline-width: thin;
  outline-color: #a1c0e7;
}
table.pvtTable tbody tr th {
  background-color: #d1e0fb !important;
  /* border: 1px solid #7b97bb !important; */
  color: #212529;
  font-size: 0.96rem !important;
  outline: solid;
  outline-offset: -1px;
  outline-width: thin;
  outline-color: #a1c0e7;
}
table.pvtTable tbody tr td {
  background-color: #f5f7f9;
  color: #212529 !important;
  font-size: 0.9rem;
  font-weight: 600;
}
.pvtAxisContainer li span.pvtAttr {
  background-color: white;
  padding: 4px 15px;
  color: #212529;
  font-size: 1rem;
  font-weight: 600;
}
.pvtDropdownCurrent {
  background-color: #fff;
  padding: 3px 3px;
  font-size: 1rem;
  font-weight: 500;
  color: #212529;
}
.pvtDropdownMenu {
  max-height: 30vh;
  overflow-y: auto;
  scrollbar-width: none;
}
.pvtDropdownValue {
  font-size: 0.9rem;
  color: #212529;
  font-weight: 500;
}

.pvtDropdown {
  width: 85%;
  margin: 2px 0px;
}
.pvtDropdown .pvtDropdownValue {
  width: 100%;
  font-size: 13px;
}
.pvtFilterBox {
  width: 200px !important;
  border-radius: 3px !important;
  border: 1px solid #c8d4e3 !important;
}

.pvtRows .pvtFilterBox {
  margin-top: 3px;
}
.pvtHorizList .pvtFilterBox {
  margin-top: 3px;
}
.pvtFilterBox input[type="text"] {
  width: 180px !important;
}
.pvtFilterBox h4 {
  margin: 8px;
  font-size: 1rem;
  color: #212529;
  font-weight: 600;
}
.pvtOnly {
  display: none !important;
}
.pvtOnlySpacer {
  display: none !important;
}
.pvtCheckContainer p {
  padding: 3px 10px;
  color: #212529;
}
.pvtCheckContainer p.selected {
  background: #d7e5ff !important;
  color: #212529 !important;
  font-weight: 500 !important;
}
.pvtCheckContainer {
  overflow-y: auto !important;
}
.chart-month {
  flex-direction: column;
}
@media (max-width: 1024px) {
  .chart-month {
    flex-direction: row;
  }
}
.pvtUi {
  width: 100%;
  z-index: 0;
  border-collapse: collapse;
}

/* .pvtUi > tbody > tr:first-child {
  background-color: yellow;
  position: sticky;
  top: 0;
  background-color: white !important;
  z-index: 3;
  height: 55px;

  box-sizing: border-box;
}
.pvtUi > tbody > tr:nth-child(2) {
  position: sticky;
  top: 56px;
  background-color: white !important;
  z-index: 2;
}
.pvtTable > thead {
  top: 118px;
}

.pvtUi > tbody > tr:first-child,
.pvtUi > tbody > tr:nth-child(2) {
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.1);
} */
/* .pvtVertList {
  position: absolute;
  height: 100%;
  width: 30%;
} */

/* .pvtUi {
  width: 100%;
  top: 0 !important;
  position: relative;
  width: 100%;
  border-collapse: collapse;
  background: white;
}
  */
.pvtUi > tbody > tr:nth-child(3) {
  position: relative;
}
.pvtUi .pvtOutput {
  padding-top: 3px;
  padding-left: 3px;
}
.pvtVertList {
  height: calc(36vh + 3px);
}
table.pvtTable {
  min-height: 36vh;
}
.pivote-scrollable {
  position: absolute;
  width: calc(83% - 3px);
  height: 36vh;
  overflow: auto;
  padding: 0 !important;
  margin-top: 3px;
  margin-left: 3px;
}
.pvtVertList {
  height: calc(36vh + 3px);
}
.pvtOutput > textarea {
  height: 36vh !important;
}
table.pvtTable {
  min-height: 40vh;
}
.pivote-scrollable {
  position: absolute;
  /* width: calc(75% - 3px); */
  height: 37vh;
  overflow: auto;
  padding: 0px !important;
  margin-top: 3px;
  margin-left: 3px;
}
.pvtOutput > textarea {
  height: 37vh !important;
}
.pvtVals {
  padding-left: 6px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
  text-align: left !important;
}
.pvtRenderers {
  text-align: left;
  background-color: #fff !important;
  padding-left: 6px !important;
  padding-top: 2px;
  padding-bottom: 2px;
}
.pvtDropdown {
  margin: 3px 0px !important;
}
.pvtColOrder {
  margin-left: 3px !important;
}
.pvtRowOrder {
  margin-left: 1px !important;
  width: 10px !important;
}
.pvtAxisContainer {
  text-align: left;
  padding: 0px 10px;
}
.pvtTable tbody td {
  text-align: center !important;
}

.pvtTable tbody .pvtTotal,
.pvtTable tbody .pvtGrandTotal {
  text-align: center !important; /* Adjust this if you prefer another alignment */
}
table.pvtTable .pvtTotalLabel {
  text-align: center !important;
}
.pvtRows {
  height: calc(37vh + 3px) !important;
}
.disabled-dropdown {
  opacity: 0.6;
  pointer-events: none;
}

.disabled-dropdown button {
  cursor: not-allowed;
}
.pvtDropdown-label {
  display: block;
  font-size: 12px;
  margin-bottom: -3px;
  text-align: left;
  color: #212529;
  font-weight: 600;
}
.pvtUi tr > td:first-child {
  outline: solid;
  z-index: 2;
  /* position: sticky; */
  left: 0;
  outline-width: thin;
  outline-color: #a2b1c6;
  outline-offset: 0px;
}
.input-placeholder::placeholder {
  color: var(--placeholder-color, gray);
  opacity: 0.7; /* Ensure the color is fully applied */
}
.menu-main {
  transform: translateX(-100%); /* Initially hidden */
  transition: transform 0.3s ease; /* Animation duration and easing */
}
.menu-main.menu-show {
  transform: translateX(0); /* Menu slides in */
}

.menu-main.menu-hide {
  transform: translateX(-100%);
  display: none; /* Menu slides out */
}
/* .legend-container {
  overflow-y: auto;
  width: 250px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #ccc;
}

.legend-background {
  fill: none;
  stroke: #ccc;
  stroke-width: 1px;
} */
.svg-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.legend-container {
  overflow-y: auto;
  height: 200px; /* Set a fixed height to enable scrolling */
  width: 250px; /* Set a fixed width */
}

.legend-background {
  fill: none;
  stroke: #ccc;
  stroke-width: 1px;
}

.legend {
  margin-bottom: 5px; /* Space between legend items */
}

.data-list {
  font-size: 13px;
  cursor: pointer;
  display: flex;
  /* color: white; */
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px;
}
.data-list:hover {
  color: #b0c4df;
}

.info-icon {
  width: 20px !important;
}

.data-list-ul {
  max-height: 30vh;
  padding-inline-start: 20px;
  border-bottom: 1px solid #ffffff4f;
  /* border-top: 1px solid #ffffff4f; */
}
.sidebar-section-header,
.sidebar-section-content {
  border-bottom: 1px solid #ffffff66;
}

.main-menu-header {
  display: flex;
  border-bottom: 2px solid #ffffff66;
}

.border-bottom {
  border-bottom: 1px solid #ffffff66 !important;
}

.text-sm {
  font-size: 0.85rem !important;
}

.MuiSvgIcon-fontSizeMedium {
  width: 20px !important;
}
.sidebar {
  transition: min-width 0.3s ease; /* Smooth transition for width */
  overflow: hidden; /* Hide content outside the sidebar during the transition */
}
.report-builder-nav-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 25px;
  justify-content: center;
  min-height: 25px;
  background-color: #10559738;
  border-radius: 5px;
  color: #0a3a67;
}
.report-builder-nav-icon:hover {
  color: rgb(67, 69, 70);
}
.border-custome {
  border: 1px solid gray;
}
.text-tiny {
  font-size: 11px;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.text-tiny {
  font-size: 11px;
}
.sketch-border {
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: gray;
}
.prop-label {
  font-weight: 600;
  font-size: 0.75rem;
  color: #374151;
}
.properties-button:hover {
  background-color: #2869a6;
}
.MuiDrawer-paper::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.MuiDrawer-paper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1px;
}
.MuiDrawer-paper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1px;
}
.MuiDrawer-paper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.btn-bgcolor {
  background-color: #105597;
}

/*.......... Calander Style .......*/

.calendar-main .rbc-date-cell {
  text-align: center;
}
.calendar-main .rbc-month-header .rbc-header {
  padding: 5px 5px;
}
.calendar-main .rbc-month-header {
  background-color: #cfdae991;
}
.calendar-main .rbc-month-view {
  border-radius: 5px;
}
.calendar-main .rbc-row-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.calendar-main .rbc-event,
.calendar-main .rbc-day-slot .rbc-background-event {
  background-color: #f2c5c5;
  padding: 4px 5px;
  border-radius: 2px;
  color: #a10000;
}
.calendar-main .rbc-toolbar button {
  padding: 0.2rem 0.9rem;
  background-color: #f1f5f9;
  font-size: 13px;
  border: 2px solid #f1f5f9;
}
.calendar-main .rbc-toolbar button.rbc-active {
  background-color: #fff;
  border: 2px solid #f1f5f9;
  box-shadow: none;
}
.calendar-main .rbc-toolbar button.rbc-active:focus {
  background-color: #fff;
  border: 2px solid #f1f5f9;
  box-shadow: none;
}
.calendar-main .rbc-toolbar button:focus {
  background-color: white;
  border: 2px solid #f1f5f9;
  box-shadow: none;
}
.calendar-main .rbc-toolbar button:hover {
  background-color: white;
  border: 2px solid #f1f5f9;
  box-shadow: none;
}
.calendar-main .rbc-toolbar button:active {
  background-color: white;
  border: 2px solid #f1f5f9;
  box-shadow: none;
}
.calendar-main .rbc-toolbar button:focus:active {
  background-color: white;
  border: 2px solid #f1f5f9;
  box-shadow: none;
}
.calendar-main .rbc-off-range-bg {
  background-color: #fff;
}
.calendar-main .rbc-off-range {
  color: #9999999c;
}
.calendar-main .rbc-toolbar .rbc-toolbar-label {
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}
.calendar-main .rbc-event.rbc-selected {
  background-color: #f2c5c5;
  padding: 4px 5px;
  border-radius: 2px;
  color: #a10000;
}
.calendar-timepicker .MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar {
  width: 3px;
  height: 1px;
}

.calendar-timepicker .MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar-thumb {
  background: #aaa9a9;
  border-radius: 1px;
}

.calendar-timepicker .MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1px;
}

.calendar-timepicker .MuiMultiSectionDigitalClock-root ul::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.calendar-timepicker .MuiMultiSectionDigitalClock-root ul {
  padding-right: 58px;
}
.calendar-timepicker .MuiMultiSectionDigitalClock-root ul li {
  font-size: 12px;
}
.calendar-availability .MuiSvgIcon-fontSizeMedium,
.calendar-icon .MuiSvgIcon-fontSizeMedium {
  width: 20px !important;
}
/* .MuiTextField-root {
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  font-size: 13px !important;
} */

.calendar-datepicker .MuiIconButton-root {
  padding-left: 0px !important;
  transition: none !important;
}
.calander .MuiSvgIcon-root {
  margin-right: 5px;
  transition: none !important;
}
.calendar-datepicker .MuiTouchRipple-root {
  display: none !important;
}

/* .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0) !important;
} */

.calendar-main .rbc-time-content::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.calendar-main .rbc-time-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1px;
}
.calendar-main .rbc-time-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 1px;
}
.calendar-main .rbc-time-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.calendar-main .rbc-time-header {
  margin-right: 2px !important;
}
/* .report-builder-textBox {
} */
textarea {
  border: 1px solid transparent;
  transition: border-color 0.3s ease;
  border-radius: 2px;
}

textarea:focus:focus-visible {
  /* border-color: gray !important; */
  outline: solid !important;
  outline-width: thin !important;
  outline-color: #5c5e60 !important;
  outline-offset: 0px;
  border-radius: 2px;
}

.custom-range {
  -webkit-appearance: none;
  width: 30%; /* Full width */
  height: 6px; /* Height of the slider */
  background: #ddd; /* Background color for the track */
  border-radius: 5px; /* Round corners */
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default appearance */
  appearance: none; /* Remove default appearance */
  width: 12px; /* Width of the thumb */
  height: 12px; /* Height of the thumb */
  border-radius: 50%; /* Round thumb */
  background: #0a3a67; /* Color of the thumb */
  cursor: pointer; /* Cursor style on hover */
}

.custom-range::-moz-range-thumb {
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Round thumb */
  background: darkblue; /* Color of the thumb */
  cursor: pointer; /* Cursor style on hover */
}

/* .pvtUi tr:nth-of-type(1),
.pvtUi tr:nth-of-type(2) {
  display: none;
} */
.dashBoard-pivot .pvtUi .pvtRenderers,
.dashBoard-pivot .pvtUi .pvtAxisContainer,
.dashBoard-pivot .pvtUi .pvtVals {
  display: none;
}

.dashBoard-pivot .pvtUi .pvtOutput {
  padding: 0px !important;
}
.pivottab .tab-content {
  padding-bottom: 0 !important;
  padding-top: 5px !important;
}
.custom-range {
  -webkit-appearance: none;
  width: 100px; /* Full width */
  height: 6px; /* Height of the slider */
  border-radius: 5px; /* Round corners */
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default appearance */
  appearance: none; /* Remove default appearance */
  width: 12px; /* Width of the thumb */
  height: 12px; /* Height of the thumb */
  border-radius: 50%; /* Round thumb */
  background: #0a3a67; /* Color of the thumb */
  cursor: pointer; /* Cursor style on hover */
}

.custom-range::-moz-range-thumb {
  width: 20px; /* Width of the thumb */
  height: 20px; /* Height of the thumb */
  border-radius: 50%; /* Round thumb */
  background: #0a3a67; /* Color of the thumb */
  cursor: pointer; /* Cursor style on hover */
}
.filter-date .MuiTextField-root {
  width: 100px !important;
  border: 1px solid gray !important;
  background-color: white !important;
  color: #374151 !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .filter-date .MuiTextField-root {
    width: 50px !important; /* Set fixed width for tablet sizes */
  }
}
.filter-date .MuiInputBase-root {
  font-size: 12px !important;
  height: 25px !important;
  padding-top: 1px !important;
}
.filter-date .MuiStack-root {
  padding-top: 0 !important;
  overflow: hidden !important;
}
.filter-date .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
  padding: 0px !important;
  top: 0px !important;
}
.filter-date .MuiFormControl-root {
  width: 100px !important;
  border-radius: 5px !important;
}
.filter-date .MuiInputBase-input {
  padding: 4px 10px !important;
}
.filter-date .MuiTypography-root {
  margin-left: 12px !important;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .filter-date .MuiFormControl-root {
    width: 76px !important; /* Set fixed width for tablet sizes */
  }
}
.dashBoard-pivot .pvtTable thead tr th {
  font-size: var(--pvt-table-font-size, 12px) !important;
  outline-color: var(--pvt-table-border-color, #a1c0e7) !important;
  border-color: var(--pvt-table-border-color, #a1c0e7) !important;
  background-color: var(--pvt-table-background-color, #d1e0fb) !important;
  color: var(--pvt-table-font-color, #000000) !important;
  /* font-family: var(--pvt-table-font-family) !important; */
  text-align: var(--pvt-table-text-align, center) !important;
}
.dashBoard-pivot .pvtTable tbody tr th {
  font-size: var(--pvt-table-font-size, 12px) !important;
  outline-color: var(--pvt-table-border-color, #a1c0e7) !important;
  border-color: var(--pvt-table-border-color, #a1c0e7) !important;
  background-color: var(--pvt-table-background-color, #d1e0fb) !important;
  color: var(--pvt-table-font-color, #000000) !important;
  /* font-family: var(--pvt-table-font-family) !important; */
  text-align: var(--pvt-table-text-align, center) !important;
}
.dashBoard-pivot .pvtTable tbody tr td {
  font-size: var(--pvt-table-font-size, 12px);
  background-color: var(--pvt-table-bg-color, #fff) !important;
  border-color: var(--pvt-table-border-color, #a1c0e7) !important;
  color: var(--pvt-table-font-color, #000000) !important;
  /* font-family: var(--pvt-table-font-family) !important; */
  font-weight: var(--pvt-table-font-weight, 400);
  text-align: var(--pvt-table-text-align, center) !important;
}
.pivottab .MuiTab-root {
  padding: 0px 25px !important;
  min-height: 30px !important;
  font-weight: 550 !important;
  text-transform: none !important;
  font-family: "Inter" !important;
}
.pivottab .MuiTab-root {
  padding: 0px 25px !important;
  min-height: 30px !important;
  font-weight: 550 !important;
  text-transform: none !important;
  font-family: "Inter" !important;
}
.pivottab .MuiTabs-flexContainer {
  justify-content: center;
  gap: 80px;
}
.pivottab .MuiSvgIcon-fontSizeMedium {
  width: 13px !important;
}
.addcol .MuiSvgIcon-root {
  height: fit-content !important;
}
.addcol .MuiSvgIcon-fontSizeMedium {
  width: 16px !important;
}
@media (max-width: 768px) {
  .pivottab .MuiTabs-flexContainer {
    gap: 50px;
  }
}
.dashBoard-pivot,
.dashBoard-pivot .pvtTable,
.dashBoard-pivot .pvtUi {
  height: 100%;
}
.dropdown .MuiSvgIcon-fontSizeMedium {
  width: 17px !important;
}
.exportmenu .MuiTypography-root {
  font-size: 14px !important;
}
.providerlist-icon .MuiSvgIcon-fontSizeMedium {
  width: 25px !important;
}
.standard-reports .reportnamecolumn {
  text-align: left !important;
  font-weight: 400 !important;
}
