/* .gm-ui-hover-effect {
  display: none !important;
} */

.gm-style img {
  /* max-width: none; */
  border-radius: 15px !important;
}

.community-name {
  font-weight: 600 !important;
}
.info-card {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  padding-right: 40px;
  min-width: 250px;
}

.info-avatar {
  width: 30px !important;
  height: 30px !important;
  font-size: 14px !important;
}

.info-chat {
  position: absolute;
  right: 2px;
  top: 30px;
}

.close-marker {
  position: absolute;
  right: 10px;
  top: 10px;
}

.gm-style-iw button.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw button.gm-ui-hover-effect {
  opacity: 1 !important;
}

.bold-medium {
  font-weight: 600 !important;
}
