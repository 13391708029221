.card-arrow {
  height: 24px;
  /* width: 24px; */
  position: absolute;
  right: 5px;
  align-items: flex-end;
}

.active-color {
  color: #1d7cd8;
}

.name {
  font-weight: 700 !important;
}

.subtext {
  font-size: 12px;
  color: #bdbdbd;
}
