.selectedChat {
  background-color: #f1f1f1;
}

.main {
  border: 0;
}

.border-top {
  border-top: 1px solid #e9ecef;
}

.options {
  position: absolute;
  right: 5px;
  top: -10px;
}

.options-icon {
  color: #4a545e;
}

.title-part-1 {
  font-family: Inter !important;
  font-weight: 700 !important;
  color: #272e35;
}

.play-icon {
  width: 12px;
  height: 12px;
}

.lastMessaged {
  color: rgba(51, 51, 51, 0.8);
}

.time {
  color: rgba(74, 84, 94, 0.6);
}

.tag-icon {
  width: 15px;
  height: 15px;
}
.tag-text {
  font-size: 10px !important;
}

.primary {
  color: #272e35;
}
