.custom-tab {
  text-transform: capitalize !important;
  font-family: Inter !important;
  font-weight: 600 !important;
}

.main-tab {
  color: #000;
  background-color: #f2f2f2;
  font-weight: 700;
}
