.today {
  font-size: 25px !important;
  font-family: Inter !important;
  font-weight: 450 !important;
}

.today-date {
  font-size: 20px !important;
  font-family: Inter !important;
  font-weight: 450 !important;
}

.dropdown-view {
  background-color: #f5f7f9 !important;
  border-radius: 6px;
  padding: 5px;
}

.dropdown-icon {
  width: 20px;
  height: 20px;
  /* tint-color: "#2F80ED"; */
}

.rightIcons {
  background-color: #f5f7f9 !important;
  /* padding: 0px !important; */
}

.publish-btn {
  padding: 5px 10px;
  font-size: 16px !important;
  background-color: #1d7c4d !important;
}

.no-data {
  font-size: 25px !important;
  font-weight: 500 !important;
}
