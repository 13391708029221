.react-calendar {
  width: auto !important;
  border: 0 !important;
}

.react-calendar button {
  border: 1px solid #ccc !important;
  height: 100% !important;
}

.react-calendar__navigation {
  margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays {
  padding: 10px 0px !important;
  border: 1px solid #ccc !important;
}

.react-calendar__month-view__days {
  height: 80px !important;
  /* height: 500px; */
}

.react-calendar__tile--hasActive {
  background-color: #2f80ed;
  color: white;
}

.calendar-custom {
  font-size: 10px;
  color: #2f80ed;
  font-weight: 600;
}

.calendar-selected {
  font-size: 10px;
  color: #fff;
  font-weight: 600;
}
