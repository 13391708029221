.cancel-title {
  font-weight: 450;
  color: #272e35;
  font-size: 18px !important;
}

.cancel-subtext {
  font-size: 14px !important;
}

.button-dont {
  font-size: 14px !important;
  width: 150px !important;
  padding: 5px !important;
  background-color: #fff !important;
  color: #4a545e !important;
  border: 1px solid #cfd6dd !important;
}

.button-cancel {
  font-size: 14px !important;
  width: 150px !important;
  padding: 5px !important;
  background-color: #eb5757 !important;
}

.button-primary {
  background-color: #2f80ed !important;
}

.note-text {
  border: 1px solid #cfd6dd !important;
  border-radius: 6px;
}

.reasons-dropdown {
  color: #272e35 !important;
  background-color: #022f520f !important;
  font-size: 14px !important;
}

.title-publish {
  font-size: 20px !important;
  font-weight: 500 !important;
}

.button-publish {
  width: 200px !important;
}

.field-input {
  /* font-size: 18px !important; */
  font-weight: 450 !important;
}

.info-header {
  background-color: #2f80ed !important;
}

.secondary-header {
  background-color: #686e75 !important;
}

.error-header {
  background-color: #eb5757 !important;
}

.success-header {
  background-color: #1d7c4d !important;
}

.info-subtext {
  font-size: 16px !important;
  color: #000 !important;
}
