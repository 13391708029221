.notification-avatar {
  color: #062d1b !important;
  font-size: 12px !important;
  width: 32px !important;
  height: 32px !important;
}

.notifications-div {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.notification-card {
  background-color: #ffffff;
}

.notification-card:hover {
  background-color: #f6f2f2;
  cursor: pointer;
}
