* {
  margin: 0;
  padding: 0;
  font-family: "Inter";
}

.border-1 {
  border: 1px solid #cfd6dd !important;
  border-radius: 6px;
}

.font-12 {
  font-family: Inter !important;
  font-size: 12px !important;
}
.font-14 {
  font-family: Inter !important;
  font-size: 14px !important;
}

.font-16 {
  font-family: Inter !important;
  font-size: 16px !important;
}

.icon {
  width: 20px !important;
  height: 20px !important;
}

.bg-1 {
  background-color: #2f80ed !important;
}

.color-1 {
  color: #2f80ed !important;
}

.color-o {
  color: #ff6b00 !important;
}

.text-color {
  color: #272e35 !important;
}

.secondary-text-color {
  color: #555f6d !important;
}

.font-medium {
  font-weight: 450 !important;
}

.MuiTypography-root,
.MuiTableCell-root {
  font-family: Inter !important;
}

.unread {
  font-size: 8px;
  color: #fff;
  width: 18px;
  height: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #eb5757;
  border-radius: 150px;
  margin-left: auto;
}

.bold-700 {
  font-weight: 700 !important;
}

.bold-600 {
  font-weight: 600 !important;
}

.two-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to display */
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-90 {
  width: 90% !important;
}

img {
  max-width: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.speciality-dropdown {
  z-index: 2;
}
.PrivateSwipeArea-root {
  display: none !important;
}

.acc-avatar {
  width: 80px !important;
  height: 80px !important;
}
