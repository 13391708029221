.command-title {
  font-size: 35px !important;
  font-weight: 600 !important;
  margin: 0px 10px !important;
  font-family: "Inter" !important;
  color: #272e35;
}

.region {
  color: #3062d4;
}

.command-time {
  color: #333333cc;
  font-family: Inter !important;
}

.provider-button {
  margin: 10px 3px 10px 13px !important;
}
