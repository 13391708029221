.tagView {
  border: 1px solid #cfd6dd !important;
  border-radius: 6px;
  background-color: #fff;
  align-self: center;
  gap: 5px;
}
.tagSuccess {
  background-color: #1d7c4d !important;
  border: 0;
}
.patientSuccess {
  background-color: #ffa500 !important;
  border: 0;
}
.tagText {
  color: #272e35;
  font-weight: 450;
  font-size: 14px !important;
  font-family: Inter !important;
}
.tagSuccessText {
  color: #fff;
}
.patientSuccessText {
  color: #fff;
}
.grey {
  background-color: #555f6d !important;
}
.greyText {
  color: #fff;
}

.tagBold {
  font-weight: 700 !important;
}

.patient {
  border: 2px solid #ffa500 !important;
  background-color: #fff !important;
}

.patientText {
  color: #ffa500 !important;
}

.metatag {
  border: 2px solid #1d7c4d !important;
  background-color: #fff !important;
}

.metatagText {
  color: #1d7c4d !important;
}

.urgent {
  border: 2px solid #fb131f !important;
  background-color: #fff !important;
}

.urgentText {
  color: #fb131f !important;
}
.urgentSuccessText {
  color: #fff !important;
}

.urgentSuccess {
  background-color: #fb131f !important;
  border: 0;
}
