.file-upload-title {
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 24px !important;
  color: #333333;
}
.file-upload-subText {
  color: #828282;
  font-family: "Inter" !important;
}
.uploadView {
  height: 250px;
  border: 1px dashed #cfd6dd;
  border-radius: 6px;
}
.uploadIcon {
  width: 30px;
  height: 30px;
}
.text {
  color: #272e35;
  font-family: "Inter";
  font-weight: 450;
  font-size: 14px;
}
.highlightText {
  color: #3062d4;
  font-family: "Inter";
  font-weight: 450;
  font-size: 14px;
}
.formats {
  color: #555f6d;
  font-family: "Inter";
  font-weight: 450;
  font-size: 14px;
}
.close-icon {
  font-size: 18px !important;
  color: #4a545e;
}
.selectedFile {
  background-color: #edf2ff;
  border-radius: 6px;
}
.uploadText {
  color: #555f6d;
}
.selectedText {
  color: #272e35;
  font-family: "Inter";
  font-weight: 450;
}
.button {
  border-radius: 6px;
}
.cancelButton {
  margin-right: 10px;
  background-color: #fff !important;
}
.addButton {
  margin-left: 10px;
}
.cancelText {
  color: #eb5757;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 450;
}
.addText {
  color: #fff;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
}
