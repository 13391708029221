.avatar-group {
  width: 100px;
  height: 100px;
  background-color: #1d7c4d;
}

.button-unset {
  all: unset;
}

.delete-button {
  width: 16;
  height: 16;
  object-fit: contain;
}

.group-name {
  font-weight: 600;
  font-family: Inter;
  color: #272e35;
}

.group-subtext {
  color: #828282;
  font-size: 14px;
}

.section-card {
  background-color: #fff;
  padding: 8px;
  cursor: pointer;
}

.right-icon {
  color: #bdbdbd;
  font-size: 16px;
}

.section-count {
  font-size: 12px !important;
  color: #bdbdbd;
}

.participants-title {
  font-weight: 700;
  font-family: Inter;
  color: #272e35;
}

.avatar-user {
  width: 25px;
  height: 25px;
  font-size: 10px;
}

.leave-text {
  color: #eb5757;
  font-weight: 450;
}

.chat-creation {
  color: #828282;
  font-size: 12px;
}

.message-text {
  color: #3062d4;
  font-weight: 450;
  font-family: Inter;
}

.group-content {
  overflow-y: auto;
  height: calc(100% - 30px);
}

.group-header {
  width: 70%;
  text-align: center;
}

.group-edit {
  background-color: rgb(255, 255, 255);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
