.fieldName {
  font-family: Inter;
  font-weight: 600 !important;
  font-size: 16px;
  color: #272e35;
}
.inputField {
  height: 40px;
  justify-content: center;
  font-family: Inter;
  font-size: 14px !important;
}
.button {
  padding: 15px;
  /* align-self: flex-start; */
  /* width: 300px !important; */
}
.region-modal-title {
  color: #fff;
  font-size: 18px;
  font-family: Inter;
}

.close-icon {
  color: #fff !important;
}
